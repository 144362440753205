import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

import { AuthenticationErrorBoundary } from './errors'
import './i18n'
import Routes from './routes'

const App = () => (
  <BrowserRouter>
    <CompatRouter>
      <AuthenticationErrorBoundary>
        <Routes />
      </AuthenticationErrorBoundary>
    </CompatRouter>
  </BrowserRouter>
)

export default App
