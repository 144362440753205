import Bugsnag from '@bugsnag/js'
import { useFormikContext } from 'formik'
import clone from 'lodash/clone'
import isArray from 'lodash/isArray'
import pickBy from 'lodash/pickBy'
import reject from 'lodash/reject'
import setWith from 'lodash/setWith'
import updateWith from 'lodash/updateWith'
import { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { CurrentFacility, FacilityType, useCurrentUser } from 'src/api'
import useActiveRole from 'src/state/role'

declare global {
  interface Window {
    Beacon: any
  }
}

export const findDeep = <T extends object, S>(
  collection: T[],
  predicate: (value: T, index: number, collection: T[]) => S,
): S | undefined => {
  for (const [i, e] of collection.entries()) {
    const result = predicate(e, i, collection)
    if (result !== undefined) {
      return result
    }
  }
}

const volumeFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

// also removes signed zero (-0)

export const formatVolumeNumber = (number: number) => volumeFormatter.format(number || 0)

export function downloadUri(data: string, name: string, inNewTab = false) {
  let element = document.createElement('a')
  element.setAttribute('href', data)
  element.setAttribute('download', name)
  if (inNewTab) {
    element.setAttribute('target', '_blank')
  }
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

interface BugsnagUserAttributes {
  id?: string
  email?: string
  fullName?: string
}

export const useBugsnagUserConfigurationEffect = (userAttributes: BugsnagUserAttributes) => {
  useEffect(() => {
    if (process.env.REACT_APP_BUGSNAG_API_TOKEN) {
      Bugsnag.setUser(userAttributes.id, userAttributes.email, userAttributes.fullName)
    }
  }, [userAttributes])
}

export const useBugsnagUserAttributes = (): BugsnagUserAttributes => {
  const { data } = useCurrentUser()
  return useMemo(() => {
    const user = data?.user
    if (user) {
      return pickBy({ id: user.id, name: user.fullName, email: user.emailAddress })
    }
    return {}
  }, [data?.user])
}

export const useGoogleAnalyticsUserId = () => {
  const { data } = useCurrentUser()
  return useMemo(() => data?.user?.id, [data?.user])
}

export function isDevelopmentStage() {
  return process.env.REACT_APP_RELEASE_STAGE !== 'production'
}

interface ColorWithCode {
  code: string
}

export function getColorGroupBackgroundClassName(group?: ColorWithCode) {
  return group ? getColorBackgroundClassName('group', group) : undefined
}

export function getColorToneBackgroundClassName(group?: ColorWithCode, tone?: ColorWithCode) {
  return group && tone
    ? getColorGroupBackgroundClassName(group) + ' ' + getColorBackgroundClassName('tone', tone)
    : undefined
}

function getColorBackgroundClassName(prefix: string, { code }: ColorWithCode) {
  return `${prefix}--${code}`
}

export const useResetDependentValuesOnChange = (condition, name, defaultValue: any = '') => {
  const { setFieldValue } = useFormikContext()
  const previousCondition = useRef(condition)
  useEffect(() => {
    if (condition !== previousCondition.current) {
      setFieldValue(name, defaultValue)
    }
    previousCondition.current = condition
  })
}

export const useResetDependentValues = (condition, name, defaultValue: any = '') => {
  /* Resets values only when condition changes from false to true.
  This gives more flexibility when we want to have different reactions depending on
  which way the condition changes.

  Can cause a render loop if parent component is re-mounted after setting field value
  */
  const { setFieldValue } = useFormikContext()
  const previousCondition = useRef(false)
  useEffect(() => {
    if (condition && !previousCondition.current) {
      setFieldValue(name, defaultValue, false)
    }
    previousCondition.current = condition
  })
}

export const getAppRootElement = () =>
  (process.env.REACT_APP_ROOT_ELEMENT_ID && document.getElementById(process.env.REACT_APP_ROOT_ELEMENT_ID)) ||
  document.body

export function usePageTracking() {
  const location = useLocation()

  const [activeRole] = useActiveRole()
  useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, { role_id: activeRole.data?.roleId })
    window.gtag('set', process.env.REACT_APP_GA_MEASUREMENT_ID, {
      traffic_type: activeRole.hasAdminRole ? 'admin' : 'external',
    })
  }, [activeRole.data?.roleId, activeRole.hasAdminRole])

  useEffect(() => {
    // window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, { page_path: location.pathname })
    // wait for user loading before sending the first page view, to have it with role id
    if (activeRole.isFetched) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
        send_to: process.env.REACT_APP_GA_MEASUREMENT_ID,
      })
    }
  }, [location, activeRole.isFetched])
}

type SupportWidgetUserAttributes = { name?: string; email?: string; signature?: string; 'role-id'?: string }

export const useSupportWidgetWithAttributes = (attributes: SupportWidgetUserAttributes | null) => {
  useEffect(() => {
    if (attributes) {
      window.Beacon('identify', attributes)
    }
  }, [attributes])
}

export const useHelpscoutUserAttributes = (): SupportWidgetUserAttributes | null => {
  const { data } = useCurrentUser()
  return useMemo(() => {
    const user = data?.user
    if (user) {
      return {
        name: user.fullName,
        email: user.emailAddress,
        signature: data.helpscoutHash,
        'role-id': data.activeRole?.roleId,
        'facility-type': data.activeRole?.facilities?.[0]?.typeId,
      }
    }
    return null
  }, [data?.helpscoutHash, data?.user, data?.activeRole])
}

export function useSupportWidgetPageTracking() {
  const { data } = useCurrentUser()

  useEffect(() => {
    const role = `${data?.activeRole?.id}: ${data?.activeRole?.roleId} (${(data?.activeRole?.facilities ?? [])
      .map((f) => `${f.organization.name}/${f.name}`)
      .concat(data?.activeRole?.organizations?.map((o) => o.name) ?? [])
      .join(',')})`

    window.Beacon('session-data', { role })
  }, [data?.activeRole])

  const location = useLocation()
  useEffect(() => {
    window.Beacon('event', { type: 'page-viewed', url: document.location.href, title: document.title })
    window.Beacon('suggest')
  }, [location])
}

/* Reloads or reinitializes the Helpscout beacon without requiring a page refresh.
It will be necessary on pages or components that display custom messages,
so that changes to the messages can be reflected. */
export const reloadBeacon = (): void => {
  const success = (function (e: Window, t: Document, n: any) {
    function a() {
      const e = t.getElementsByTagName('script')[0]
      const n = t.createElement('script')
      n.type = 'text/javascript'
      n.async = true
      n.src = 'https://beacon-v2.helpscout.net'
      e.parentNode?.insertBefore(n, e)
    }

    if (
      (e.Beacon = n =
        (t: any, n: any, a: any) => {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a })
        })
    ) {
      n.readyQueue = []
    }

    if (t?.readyState === 'complete') {
      a()
      return true
    } else {
      e.addEventListener('load', a, false)
    }

    return false
  })(window, document, window.Beacon || function () {})

  if (success) {
    window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_ID)
  } else {
    if (process.env.REACT_APP_BUGSNAG_API_TOKEN) {
      Bugsnag.notify('Helpscout beacon script failed to load', (event) => {
        event.context = 'Reloading chat beacon'
      })
    }
  }
}

/* This method allows you to modify when Messages are displayed.
   There are currently two options:
   - delay: pass a value in milliseconds to override the default 3 second delay before a Message is displayed
   - force: force a Message to be displayed, even if it has already been shown to the customer
*/
export const showHelpscoutMessage = (messageId: string, delay?: number, force?: boolean) => {
  reloadBeacon()
  window.Beacon('show-message', messageId, { ...(delay && { delay }), ...(force && { force }) })
}

export function useAnalyticsUserConfigurationEffect(userId?: string) {
  useEffect(() => {
    window.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, { user_id: userId })
    window.gtag('set', process.env.REACT_APP_GA_MEASUREMENT_ID, { app_user_id: userId })
  }, [userId])
}

export function useAnalyticsRoleDimensionTrackingEffect(userRoleId?: string) {
  useEffect(() => {
    window.gtag('set', process.env.REACT_APP_GA_MEASUREMENT_ID, { role_id: userRoleId })
  }, [userRoleId])
}

export function setGaStorageConsent(isConsented: boolean) {
  if (isConsented) {
    window.gtag('consent', 'update', {
      analytics_storage: 'granted',
      security_storage: 'granted',
      // still not granting ad_storage
      functionality_storage: 'granted',
      personalization_storage: 'granted',
    })
  } else {
    window.gtag('consent', 'update', {
      analytics_storage: 'denied',
      security_storage: 'denied',
      ad_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
    })
  }
}

export function hasRecyclerOrWasteHandlerType(facilities: CurrentFacility[]) {
  return facilities.some((f) => f.typeId === FacilityType.Recycler || f.typeId === FacilityType.WasteHandler)
}

export const stringToBool = (string: string) => (string === 'true' ? true : false)

export function alphanumericSorter(a: string | number, b: string | number) {
  /* Sort comparator for strings (case-insensitive) or numbers (includes signs) */
  let numA = 0
  let numB = 0
  let isANumber = false
  let isBNumber = false

  if (typeof a === 'number') {
    numA = a
    isANumber = true
  }

  if (typeof b === 'number') {
    numB = b
    isBNumber = true
  }

  if (isANumber && isBNumber) {
    const signA = Math.sign(numA)
    const signB = Math.sign(numB)

    if (signA === signB) {
      return Math.abs(numA) - Math.abs(numB)
    } else if (signA === 0) {
      return 1
    } else if (signB === 0) {
      return -1
    } else {
      return signA - signB
    }
  } else {
    /* Compares the two strings a and b based on the language and locale of the user's system  */
    return String(a).localeCompare(String(b))
  }
}

/**
 * Returns a copy of the object with value set in path.
 * Shallowly copies all other recursive fields that were not changed.
 */
export function setDeepImmutable(object: Object, path: string, value: unknown) {
  return setWith(clone(object), path, value, clone)
}

/**
 * Returns a copy of the object with value appended in path.
 * If the value does not exist at the specified path or is not an array, creates a new array.
 * Shallowly copies all other recursive fields that were not changed.
 */
export function appendDeepImmutable(object: Object, path: string, valueToAppend: unknown) {
  return updateWith(
    clone(object),
    path,
    (currentValue) => (isArray(currentValue) ? currentValue.concat(valueToAppend) : [valueToAppend]),
    clone,
  )
}

/**
 * Returns a copy of the object with value removed from list in path.
 * If the specified path is not an array, creates a new array.
 * Shallowly copies all other recursive fields that were not changed.
 */
export function removeMatchingFromDeepImmutableList(object: Object, path: string, matcher: object) {
  return updateWith(
    clone(object),
    path,
    (currentValue) => (isArray(currentValue) ? reject(currentValue, matcher) : []),
    clone,
  )
}
