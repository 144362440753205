import { GlobalMessageProvider } from 'src/state/globalMessage'

import GlobalMessageModal from './GlobalMessageModal'
import Header from './Header'
import MainMenu from './MainMenu'
import TrackingConsent from './TrackingConsent'

export default function TopFrame({ children }) {
  return (
    <section role="main" className="print:block grid grid-main h-full w-full">
      <GlobalMessageProvider>
        <Header className="grid-main-header print:hidden" />
        <MainMenu className="grid-main-sidebar print:hidden" />
        <div className="grid-main-body pb-20">{children}</div>
        <TrackingConsent />
        <GlobalMessageModal />
      </GlobalMessageProvider>
    </section>
  )
}
