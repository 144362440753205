import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'

import AppFrame from 'src/components/AppFrame'
import CenterSpinner from 'src/components/CenterSpinner'
import { PrivateRoute, RoleSpecificRoute, RoleSpecificRouteCompat } from 'src/route-config'
import {
  useAnalyticsUserConfigurationEffect,
  useBugsnagUserAttributes,
  useBugsnagUserConfigurationEffect,
  useGoogleAnalyticsUserId,
  useHelpscoutUserAttributes,
  usePageTracking,
  useSupportWidgetPageTracking,
  useSupportWidgetWithAttributes,
} from 'src/utils'

const Home = lazy(() => import('./Home'))
const CurrentFacilityInventory = lazy(() => import('./CurrentFacilityInventory'))
const CurrentFacilityPurchaseOrders = lazy(() => import('./CurrentFacilityPurchaseOrders'))
const AdminPurchaseOrders = lazy(() => import('./admin/purchase-orders'))
const CurrentOrganizationInventories = lazy(() => import('./CurrentOrganizationInventories'))
const CurrentOrganizationUsers = lazy(() => import('./CurrentOrganizationUsers'))
const CurrentProjectUsers = lazy(() => import('./CurrentProjectUsers'))
const Shipments = lazy(() => import('./Shipments'))
const ProfileRoute = lazy(() => import('./profile'))
const FacilityList = lazy(() => import('./FacilityList'))
const UserSettings = lazy(() => import('./UserSettings'))
const Organization = lazy(() => import('./Organization'))
const SignUpSurvey = lazy(() => import('./SignUpSurvey'))
const AdminUserList = lazy(() => import('./admin/users'))
const AdminHandlers = lazy(() => import('./admin/handlers'))
const AdminTransactionOverview = lazy(() => import('./admin/transaction-overview'))
const TransactionOverview = lazy(() => import('./transaction-overview'))
const SignUp = lazy(() => import('./SignUp'))
const LogIn = lazy(() => import('./LogIn'))
const AdminBrandList = lazy(() => import('./admin/brands'))
const AdminManufacturerList = lazy(() => import('./admin/manufacturers'))
const AdminRecyclerList = lazy(() => import('./admin/recyclers'))
const AdminShipmentList = lazy(() => import('./admin/shipments'))
const ProfileSurvey = lazy(() => import('./ProfileSurvey'))
const ManufacturerList = lazy(() => import('./ManufacturerList'))
const Recyclers = lazy(() => import('./Recyclers'))
const CurrentBrandUsers = lazy(() => import('./CurrentBrandUsers'))
const CurrentBrandInventory = lazy(() => import('./CurrentBrandInventory'))
const AdminInventory = lazy(() => import('./admin/inventory'))
const HandlerList = lazy(() => import('./HandlerList'))
const Statistics = lazy(() => import('./Statistics'))
const RecyclerSupplyInventory = lazy(() => import('./RecyclerSupplyInventory'))
const Suppliers = lazy(() => import('./Suppliers'))
const SupplierList = lazy(() => import('./SupplierList'))
const RecycledProducts = lazy(() => import('./RecycledProducts'))
const AdminRecycledProducts = lazy(() => import('./admin/production'))

export default function Routes() {
  const { pathname } = useLocation()
  useSupportWidgetWithAttributes(useHelpscoutUserAttributes())
  useBugsnagUserConfigurationEffect(useBugsnagUserAttributes())
  useAnalyticsUserConfigurationEffect(useGoogleAnalyticsUserId())
  usePageTracking()
  useSupportWidgetPageTracking()
  return (
    <Suspense fallback={<CenterSpinner />}>
      <Switch>
        <Route path="/:url*(/+)" render={() => <Redirect to={pathname.slice(0, -1)} />} />
        <CompatRoute path="/manufacturer-survey">
          <ProfileSurvey />
        </CompatRoute>
        <Route>
          <AppFrame>
            <Suspense fallback={<CenterSpinner />}>
              <Switch>
                <RoleSpecificRoute path="/sign-up/handler-profile/:signUpCode">
                  <SignUp signUpSource="HANDLER_SURVEY" />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/sign-up/recycler-profile/:signUpCode">
                  <SignUp signUpSource="RECYCLER_SURVEY" />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/sign-up/manufacturer-profile/:signUpCode">
                  <SignUp signUpSource="MANUFACTURER_SURVEY" />
                </RoleSpecificRoute>
                <RoleSpecificRoute exact path="/sign-up/:signUpCode?">
                  <SignUp />
                </RoleSpecificRoute>
                <PrivateRoute path="/user">
                  <UserSettings />
                </PrivateRoute>
                <RoleSpecificRouteCompat path="/organization">
                  <Organization successRedirectUrl="/facilities" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRoute path="/users">
                  <CurrentOrganizationUsers />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/brand/users">
                  <CurrentBrandUsers signUpPathTemplate="/sign-up/manufacturer-profile/:signUpCode" />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/project/users">
                  <CurrentProjectUsers />
                </RoleSpecificRoute>
                <RoleSpecificRouteCompat path="/admin/users">
                  <AdminUserList />
                </RoleSpecificRouteCompat>
                <RoleSpecificRoute path="/inventory/facility/:id" exact component={CurrentOrganizationInventories} />
                <RoleSpecificRouteCompat path="/facilities">
                  <FacilityList recyclerPageUrl="/recyclers" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRoute path="/manufacturer-profile" component={SignUpSurvey}>
                  <SignUpSurvey
                    profilePathTemplate="/facilities/:id/profile/production"
                    facilityTypeId="MANUFACTURER"
                  />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/handler-profile" component={SignUpSurvey}>
                  <SignUpSurvey profilePathTemplate="/facilities/:id/profile/general" facilityTypeId="WASTE_HANDLER" />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/recycler-profile" component={SignUpSurvey}>
                  <SignUpSurvey profilePathTemplate="/facilities/:id/profile/general" facilityTypeId="RECYCLER" />
                </RoleSpecificRoute>
                <RoleSpecificRoute path="/inventory" exact>
                  <CurrentFacilityInventory
                    shipmentUrlPrefix="/shipments"
                    purchaseOrderUrlTemplate="/purchase-orders/:id/?edit=true"
                    recycledProductUrlTemplate="/production/:id/"
                  />
                </RoleSpecificRoute>
                <RoleSpecificRouteCompat path="/purchase-orders">
                  <CurrentFacilityPurchaseOrders inventoryUrl="/inventory" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRouteCompat path="/admin/purchase-orders">
                  <AdminPurchaseOrders inventoryUrl="/admin/inventory" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRoute path="/brand/inventory" component={CurrentBrandInventory} />
                <RoleSpecificRouteCompat path="/shipments">
                  <Shipments
                    profileUrlTemplate="/suppliers/:id/profile"
                    handlerProfileUrlTemplate="/handlers/:id/profile"
                  />
                </RoleSpecificRouteCompat>
                <RoleSpecificRouteCompat path="/profile">
                  <ProfileRoute recyclerPageUrl="/recyclers" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRouteCompat path="/handlers" component={HandlerList} />
                <RoleSpecificRouteCompat path="/admin/brands" component={AdminBrandList} />
                <RoleSpecificRouteCompat path="/admin/manufacturers">
                  <AdminManufacturerList shipmentUrlPrefix="/admin/shipments/outgoing" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRouteCompat path="/admin/handlers">
                  <AdminHandlers shipmentUrlPrefix="/admin/shipments" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRouteCompat path="/admin/recyclers">
                  <AdminRecyclerList shipmentUrlPrefix="/admin/shipments" />
                </RoleSpecificRouteCompat>
                <RoleSpecificRoute exact path="/login" component={LogIn} />
                <RoleSpecificRouteCompat path="/admin/shipments" component={AdminShipmentList} />
                <RoleSpecificRouteCompat path="/admin/inventory">
                  <AdminInventory
                    inventoryPathTemplate="/admin/manufacturers/:id/inventory"
                    profilePathTemplate="/admin/manufacturers/:id/profile"
                  />
                </RoleSpecificRouteCompat>
                <RoleSpecificRouteCompat path="/admin/production" component={AdminRecycledProducts} />
                <RoleSpecificRoute path="/admin/transaction-overview" component={AdminTransactionOverview} />
                <RoleSpecificRoute path="/transaction-overview" component={TransactionOverview} />
                <RoleSpecificRouteCompat path="/manufacturers" component={ManufacturerList} />
                <RoleSpecificRouteCompat path="/statistics" component={Statistics} />
                <RoleSpecificRouteCompat path="/recyclers" component={Recyclers} />
                <RoleSpecificRoute path="/supply-inventory">
                  <RecyclerSupplyInventory
                    inventoryUrlTemplate="/suppliers/:id/inventory"
                    profileUrlTemplate="/suppliers/:id/profile"
                    handlerProfileUrlTemplate="/handlers/:id/profile"
                    handlerInventoryUrlTemplate="/handlers/:id/inventory"
                  />
                </RoleSpecificRoute>
                <RoleSpecificRouteCompat path="/suppliers" component={Suppliers} />
                <RoleSpecificRouteCompat path="/suppliers-overview" component={SupplierList} />
                <RoleSpecificRouteCompat path="/production" component={RecycledProducts} />
                <Route exact path="/:path(logged-in)?" component={Home} />
                <Redirect to="/" />
              </Switch>
            </Suspense>
          </AppFrame>
        </Route>
      </Switch>
    </Suspense>
  )
}
