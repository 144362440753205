import classNames from 'classnames'
import {
  ErrorMessage,
  ErrorMessageProps,
  FastField,
  Field,
  FieldHookConfig,
  FieldInputProps,
  FormikContextType,
  getIn,
  useField,
  useFormikContext,
} from 'formik'
import clone from 'lodash/clone'
import find from 'lodash/find'
import get from 'lodash/get'
import intersectionBy from 'lodash/intersectionBy'
import isString from 'lodash/isString'
import pick from 'lodash/pick'
import reject from 'lodash/reject'
import round from 'lodash/round'
import set from 'lodash/set'
import setWith from 'lodash/setWith'
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { MdCheck, MdClose } from 'react-icons/md'

import { Checkbox, ErrorMessageElement, RadioButton, RichSelectOptionType } from 'src/components/Elements'
import { useClassifierOptionList } from 'src/hooks'
import { useIsFormEditable } from 'src/utils/Normik'

import {
  FieldLabel,
  FileInputField,
  InputField,
  InputFieldProps,
  MultiSelectField,
  MultiSelectFieldProps,
  ReadOnlyField,
  RichSelectField,
  RichSelectFieldProps,
  SelectField,
  SelectFieldProps,
  TextAreaField,
} from './Fields'

export interface InputFormFieldProps extends Omit<InputFieldProps, 'isChanged'> {
  name: string
  onValueChange?: (value: string) => void
  fastField?: boolean
  align?: 'start' | 'center' | 'end'
}

export const InputFormField = ({
  name,
  onValueChange,
  fastField = false,
  align = 'center',
  ...props
}: InputFormFieldProps) => {
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange)
  const Component = fastField ? FastField : Field
  const isEditable = useIsFormEditable()
  return (
    <Component name={name}>
      {({ field, meta }) =>
        isEditable ? (
          <InputField
            {...props}
            {...field}
            name={name}
            id={name}
            isChanged={meta.initialValue !== meta.value}
            hasError={!!meta.error}
            onChange={interceptedOnChange}
          >
            <FormFieldErrorMessage name={name} />
          </InputField>
        ) : (
          <ReadOnlyField
            label={props.label}
            className={classNames(props.className, 'flex', {
              'items-start': align === 'start',
              'items-center': align === 'center',
              'items-end': align === 'end',
            })}
          >
            <Trans i18nKey="INPUT_READONLY">
              <span>{{ prefix: props.prefix || '' }}</span>
              <span
                className={classNames({
                  'ml-1': props.prefix,
                  'mr-1': props.suffix,
                })}
              >
                {{ value: meta.value || '?' }}
              </span>
              <span>{{ suffix: props.suffix || '' }}</span>
            </Trans>
          </ReadOnlyField>
        )
      }
    </Component>
  )
}

export interface SelectFormFieldProps extends SelectFieldProps {
  onValueChange?: (value: string) => void
  fastField?: boolean
  name: string
  strikeThrough?: boolean
  disabled?: boolean
}

export const SelectFormField = ({
  name,
  onValueChange = undefined,
  fastField = true,
  options,
  strikeThrough = false,
  ...props
}: SelectFormFieldProps) => {
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange)
  useValidateValueOnOptionsChange(name, options)
  const isEditable = useIsFormEditable()
  const Component = fastField ? FastField : Field
  return (
    <Component name={name}>
      {({ field, meta }) => (
        <SelectField
          isChanged={meta.initialValue !== meta.value}
          hasError={!!meta.error}
          options={options}
          {...field}
          {...props}
          onChange={interceptedOnChange}
          readOnly={props.readOnly || !isEditable}
        >
          <FormFieldErrorMessage name={name} />
        </SelectField>
      )}
    </Component>
  )
}

export function ReadOnlySelectFormField({
  label = undefined,
  value,
  options,
  className,
  strikeThrough,
}: {
  label?: string
  value?: any
  options?: { id: string; name: string }[]
  className?: string
  strikeThrough?: boolean
}) {
  return (
    <ReadOnlyField className={classNames(className, { 'line-through': strikeThrough })} label={label}>
      {options?.find((o) => o.id?.toString() === value?.toString())?.name}
    </ReadOnlyField>
  )
}

type MultiSelectFieldHookConfig = FieldHookConfig<string[]> & {
  options: { id: string | number }[]
}

const useValidateMultiValueOnOptionsChange = (
  name: string,
  options: { id: string | number; name: string; options?: { id: string | number; name: string }[] }[] = [],
) => {
  const [{ value }, , { setValue }] = useField({ name, options } as MultiSelectFieldHookConfig)

  useEffect(() => {
    if (value?.length > 0) {
      const flatOptions = options.flatMap((o) => o.options ?? [o])
      const validValues = intersectionBy(value, flatOptions, (i) =>
        typeof i === 'object' ? i.id.toString() : i.toString(),
      )
      if (validValues.length < value.length) {
        setValue(validValues)
      }
    }
  }, [options, value, setValue])
}

export interface MultiSelectFormFieldProps
  extends Omit<MultiSelectFieldProps, keyof FieldInputProps<MultiSelectFieldProps['value']>> {
  onValueChange?: (value: string[]) => void
  fastField?: boolean
  name: string
}

export const MultiSelectFormField = ({
  name,
  onValueChange = undefined,
  options = [],
  fastField = true,
  ...props
}: MultiSelectFormFieldProps) => {
  const isEditable = useIsFormEditable()
  const { t } = useTranslation()

  useValidateMultiValueOnOptionsChange(name, options)
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange)
  const Component = fastField ? FastField : Field
  return (
    <Component name={name} isMulti>
      {({ field, meta }) =>
        isEditable ? (
          <MultiSelectField
            {...props}
            {...field}
            isChanged={meta.initialValue !== meta.value}
            hasError={!!meta.error}
            onChange={interceptedOnChange}
            options={options}
          >
            {Object.entries(meta.error || {}).map(([k, v]) => (
              <ErrorMessageElement key={k}>
                {t('Error for item {{value}}', { value: `${parseInt(k) + 1}: ${v}`, ns: 'error' })}
              </ErrorMessageElement>
            ))}
          </MultiSelectField>
        ) : (
          <ReadOnlyMultiSelectFormField value={meta.value} options={options} />
        )
      }
    </Component>
  )
}

export function ReadOnlyMultiSelectFormField({
  value,
  options,
  label = undefined,
}: {
  value: any[]
  options?: any[]
  label?: string
}) {
  return (
    <ReadOnlyField as="ul" label={label}>
      {value.map((v) => (
        <li key={v}>{options?.find((o) => o.id?.toString() === v?.toString())?.name}</li>
      ))}
    </ReadOnlyField>
  )
}

interface RichSelectFormFieldProps<T extends RichSelectOptionType>
  extends Omit<RichSelectFieldProps<T>, keyof FieldInputProps<T>> {
  onValueChange?: (value: T) => void
  fastField?: boolean
  name: string
}

interface RichSelectFormFieldComponent {
  <T extends RichSelectOptionType>(props: RichSelectFormFieldProps<T>): JSX.Element
}

export const RichSelectFormField: RichSelectFormFieldComponent = ({
  name,
  onValueChange = undefined,
  options = [],
  fastField = true,
  strikeThrough = false,
  defaultValue = undefined,
  ...props
}) => {
  const isEditable = useIsFormEditable()
  useValidateValueOnRichOptionsChange<(typeof options)[number]>(name, options)
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange, (value) => value?.value?.toString() ?? null)
  const Component = fastField ? FastField : Field

  return (
    <Component name={name}>
      {({ field, meta }) =>
        isEditable && !props.readOnly ? (
          <RichSelectField
            {...props}
            {...field}
            value={
              // support both grouped (has sub-key) and not grouped options
              find(
                options.flatMap((o) => o.options ?? [o]),
                (o) => o.value.toString() === (field.value ? field.value?.toString() : defaultValue?.value),
              ) ?? ''
            }
            isChanged={meta.initialValue !== meta.value}
            hasError={!!meta.error}
            onChange={interceptedOnChange}
            options={options}
          >
            <FormFieldErrorMessage name={name} />
          </RichSelectField>
        ) : (
          <ReadOnlyRichSelectFormField
            label={props.label}
            value={field.value}
            options={options}
            strikeThrough={strikeThrough}
          />
        )
      }
    </Component>
  )
}

export function ReadOnlyRichSelectFormField({
  label = undefined,
  value,
  options,
  className = '',
  strikeThrough = false,
}) {
  return (
    <ReadOnlyField label={label} className={className + (strikeThrough ? ' line-through' : '')}>
      {
        find(
          options.flatMap((o) => o.options ?? [o]),
          (o) => o.value.toString() === value?.toString(),
        )?.label
      }
    </ReadOnlyField>
  )
}

interface RichObjectSelectFormFieldProps<T extends RichSelectOptionType>
  extends Omit<RichSelectFieldProps<T>, keyof FieldInputProps<T>> {
  onValueChange?: (value: T) => void
  fastField?: boolean
  name: string
}

interface RichObjectSelectFormFieldComponent {
  <T extends RichSelectOptionType>(props: RichObjectSelectFormFieldProps<T>): JSX.Element
}

export const RichObjectSelectFormField: RichObjectSelectFormFieldComponent = ({
  name,
  onValueChange = undefined,
  options = [],
  fastField = true,
  ...props
}) => {
  useValidateValueOnRichOptionsChange<(typeof options)[number]>(name, options)
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange)
  const Component = fastField ? FastField : Field

  return (
    <Component name={name}>
      {({ field, meta }) => (
        <RichSelectField
          {...props}
          {...field}
          isChanged={meta.initialValue !== meta.value}
          hasError={!!meta.error}
          onChange={interceptedOnChange}
          options={options}
        >
          <FormFieldErrorMessage name={name} />
        </RichSelectField>
      )}
    </Component>
  )
}

interface RichCombinedSelectFormFieldProps<T extends Omit<RichSelectOptionType, 'value'>>
  extends Omit<RichSelectFieldProps<T & RichSelectOptionType>, keyof FieldInputProps<T & RichSelectOptionType>> {
  onValueChange?: (value: T) => void
  fastField?: boolean
  name: string
  keys: string[]
  outputKeys?: string[]
}

interface RichCombinedSelectFormFieldComponent {
  <T extends RichSelectOptionType>(props: RichCombinedSelectFormFieldProps<T>): JSX.Element
}

function useTransformObject(inputKeys?: string[], outputKeys?: string[]) {
  return useCallback(
    (value) => {
      const resolvedInputKeys = inputKeys || outputKeys || []
      const resolvedOutputKeys = outputKeys || inputKeys || []
      return resolvedInputKeys.reduce((obj, key, i) => set(obj, resolvedOutputKeys[i], get(value, key, '')), {})
    },
    [inputKeys, outputKeys],
  )
}

function useGetHash(keys: string[]) {
  return useCallback((value) => Object.values(pick(value, keys)).join('|'), [keys])
}

export const RichCombinedSelectFormField: RichCombinedSelectFormFieldComponent = ({
  name,
  keys,
  outputKeys,
  onValueChange = undefined,
  options = [],
  fastField = true,
  ...props
}) => {
  const Component = fastField ? FastField : Field

  const getValueFromOption = useTransformObject(keys, outputKeys)
  const getOptionFromValue = useTransformObject(outputKeys, keys)
  const getOptionHash = useGetHash(keys)

  const isEditable = useIsFormEditable()

  const hashedOptions = useMemo(
    () => options.map((o) => ({ ...o, label: o.label, value: getOptionHash(o) })),
    [options, getOptionHash],
  )
  useValidateValueOnRichOptionsChange<(typeof options)[number]>(name, hashedOptions)
  const interceptedOnChange = useInterceptedOnChangeObject(name, onValueChange, getValueFromOption)
  const namePrefix = name ? `${name}.` : ''
  return (
    <Component name={name}>
      {({ field, meta }) =>
        isEditable ? (
          <RichSelectField
            {...props}
            {...field}
            value={
              find(hashedOptions, (o) => o.value === getOptionHash(getOptionFromValue(meta.value))) ??
              // if no options then keep value as undefined as they haven't loaded yet
              (hashedOptions ? null : undefined)
            }
            isChanged={
              getOptionHash(getOptionFromValue(meta.initialValue)) !== getOptionHash(getOptionFromValue(meta.value))
            }
            hasError={!!meta.error}
            onChange={interceptedOnChange}
            options={hashedOptions}
          >
            {(outputKeys || keys).map((k) => (
              <FormFieldErrorMessage key={k} name={`${namePrefix}${k}`} />
            ))}
          </RichSelectField>
        ) : (
          <ReadOnlyField label={props.label} className="flex items-center space-x-1">
            {find(hashedOptions, (o) => o.value === getOptionHash(getOptionFromValue(meta.value)))?.label}
          </ReadOnlyField>
        )
      }
    </Component>
  )
}

export const TextAreaFormField = ({ name, fastField = true, ...props }) => {
  const Component = fastField ? FastField : Field

  const isEditable = useIsFormEditable()
  return (
    <Component name={name}>
      {({ field, meta }) =>
        isEditable ? (
          <TextAreaField
            {...field}
            {...props}
            id={name}
            isChanged={meta.initialValue !== meta.value}
            hasError={!!meta.error}
          >
            <FormFieldErrorMessage name={name} />
          </TextAreaField>
        ) : (
          <ReadOnlyField label={props.label}>
            <p className="px-4">{meta.value || '?'}</p>
          </ReadOnlyField>
        )
      }
    </Component>
  )
}

export const CheckboxFormField = ({
  name,
  isRequired = false,
  label = '',
  className = '',
  ...props
}: {
  name: string
  isRequired?: boolean
  label?: ReactNode
  className?: string
}) => {
  const isEditable = useIsFormEditable()

  return (
    <FastField name={name}>
      {({ field, meta }) =>
        isEditable ? (
          <>
            <label className={classNames('flex flex-row items-center', className)}>
              <Checkbox
                required={isRequired}
                isChanged={meta.initialValue !== meta.value}
                hasError={!!meta.error}
                {...props}
                {...field}
              />
              {label && (
                <div className="ml-2">
                  {label}
                  {isRequired ? <span className="ml-1 text-red-700">*</span> : ''}
                </div>
              )}
            </label>
            <FormFieldErrorMessage name={name} />
          </>
        ) : (
          <ReadOnlyCheckboxFormField label={label} value={meta.value} />
        )
      }
    </FastField>
  )
}

export const ListCheckboxFormField = ({
  name,
  value,
  isRequired = false,
  label = '',
  className = '',
  ...props
}: {
  name: string
  isRequired?: boolean
  value: string | number
  label?: ReactNode
  className?: string
}) => {
  const isEditable = useIsFormEditable()

  return (
    <FastField name={name}>
      {({ field, meta, form }) => {
        const isChecked = meta.value?.includes(value)
        return isEditable ? (
          <>
            <label className={classNames('flex flex-row items-center', className)}>
              <Checkbox
                required={isRequired}
                isChanged={meta.initialValue?.includes(value) !== isChecked}
                hasError={!!meta.error}
                {...props}
                {...field}
                value={isChecked}
                onChange={() =>
                  form.setFieldValue(name, isChecked ? meta.value.filter((v) => v !== value) : meta.value.concat(value))
                }
              />
              {label && (
                <div className="ml-2">
                  {label}
                  {isRequired ? <span className="ml-1 text-red-700">*</span> : ''}
                </div>
              )}
            </label>
            <FormFieldErrorMessage name={name} />
          </>
        ) : (
          <ReadOnlyCheckboxFormField label={label} value={meta.value} />
        )
      }}
    </FastField>
  )
}

export function ReadOnlyCheckboxFormField({ label, value }: { label?: ReactNode; value: boolean }) {
  return (
    <ReadOnlyField className="flex space-x-2 items-center" label={label}>
      {value ? <MdCheck className="w-6 h-6" /> : <MdClose className="w-6 h-6" />}
    </ReadOnlyField>
  )
}

export const ListCheckboxWithInputFormField = ({
  name,
  isRequired = false,
  label = '',
  className = '',
  option,
  fieldName,
  inputFieldName,
}: {
  name: string
  isRequired?: boolean
  label?: ReactNode
  className?: string
  option: { id: string; descriptionRequired: boolean }
  fieldName: string
  inputFieldName: string
}) => {
  const isEditable = useIsFormEditable()
  const [parentValue, setParentValue, initialParentValues] = useFieldOrFormValue<any[]>(name)
  const index = parentValue.findIndex((o) => o?.[fieldName] === option.id)
  const initialIndex = initialParentValues.findIndex((o) => o?.[fieldName] === option.id)

  const handleChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setParentValue(parentValue.concat({ [fieldName]: option.id, [inputFieldName]: '' }))
      } else {
        setParentValue(reject(parentValue, { [fieldName]: option.id }))
      }
    },
    [setParentValue, parentValue, fieldName, option.id, inputFieldName],
  )

  const isChanged = useMemo(() => {
    return parentValue?.[index] !== initialParentValues?.[initialIndex]
  }, [index, initialIndex, parentValue, initialParentValues])

  return isEditable ? (
    <>
      <label className={classNames('flex flex-row items-center', className)}>
        <Checkbox required={isRequired} isChanged={isChanged} value={index !== -1} onChange={handleChange} />
        {label && (
          <div className="ml-2">
            {label}
            {isRequired ? <span className="ml-1 text-red-700">*</span> : ''}
          </div>
        )}
        {option.descriptionRequired && index !== -1 && (
          <InputFormField
            className="ml-2 w-full"
            isRequired
            fastField={false}
            name={`${name}.${index}.${inputFieldName}`}
          />
        )}
      </label>
      <FormFieldErrorMessage name={`${name}.${index}.${fieldName}`} />
    </>
  ) : (
    <ReadOnlyCheckboxWithInputFormField
      label={label}
      value={parentValue[index]}
      fieldName={fieldName}
      inputFieldName={inputFieldName}
    />
  )
}

export function ReadOnlyCheckboxWithInputFormField({
  label,
  value,
  fieldName,
  inputFieldName,
}: {
  label?: ReactNode
  value: any
  fieldName: string
  inputFieldName: string
}) {
  return (
    <ReadOnlyField className="flex space-x-2 items-center" label={label}>
      {value?.[fieldName] ? <MdCheck className="w-6 h-6" /> : <MdClose className="w-6 h-6" />}
      <span className="font-semibold">{value?.[inputFieldName]}</span>
    </ReadOnlyField>
  )
}

export const YesNoFormField = ({
  name,
  isRequired = false,
  label = '',
  className = '',
}: {
  name: string
  isRequired?: boolean
  label?: ReactNode
  className?: string
}) => {
  const { t } = useTranslation()
  const isEditable = useIsFormEditable()
  const parseValue = useCallback((v) => (v === 'true' ? true : v === 'false' ? false : null), [])
  const formatValue = useCallback((v) => (v === true ? 'true' : v === false ? 'false' : ''), [])
  return isEditable ? (
    <>
      <div className="flex space-x-2">
        {label && (
          <h3 className="text-lg px-3">
            {label}
            {isRequired ? <span className="ml-1 text-red-700">*</span> : ''}
          </h3>
        )}
        <label className={classNames('flex flex-row items-center', className)}>
          <RadioButtonFormField
            label={t('Yes')}
            name={name}
            isRequired={isRequired}
            value={true}
            parse={parseValue}
            format={formatValue}
          />
        </label>
        <label className={classNames('flex flex-row items-center', className)}>
          <RadioButtonFormField
            label={t('No')}
            name={name}
            isRequired={isRequired}
            value={false}
            parse={parseValue}
            format={formatValue}
          />
        </label>
      </div>
      <FormFieldErrorMessage name={name} />
    </>
  ) : (
    <ReadOnlyYesNoFormField label={label} name={name} />
  )
}

const ReadOnlyYesNoFormField = ({ label, name }) => {
  const [, { value }] = useField(name)
  return (
    <div className="flex space-x-2 items-center">
      <h3 className="text-lg px-3">{label}</h3>
      <span className="font-semibold">{value === true ? 'Yes' : value === false ? 'No' : '?'}</span>
    </div>
  )
}

export const FormFieldErrorMessage = ({ name }) => <FormikErrorMessage component={ErrorMessageElement} name={name} />

function FormikErrorMessage({ name, ...rest }: ErrorMessageProps) {
  const [, { error }] = useField(name)
  if (!isString(error)) {
    return null
  }

  return <ErrorMessage name={name} {...rest} />
}

export const RadioButtonFormField = ({
  name,
  isRequired = false,
  label = undefined,
  className = '',
  value,
  onValueChange = undefined,
  parse = (v) => v,
  format = (v) => v,
}: {
  name: string
  isRequired?: boolean
  label?: string
  className?: string
  value: any
  onValueChange?: any
  parse?: (value: any) => any
  format?: (value: any) => any
}) => {
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange, parse)

  return (
    <Field type="radio" name={name} value={format(value)}>
      {({ field, meta }) => (
        <label className={classNames('flex flex-row items-center', className)}>
          <RadioButton
            isRequired={isRequired}
            isChanged={(meta.initialValue === value || meta.value === value) && meta.initialValue !== meta.value}
            hasError={!!meta.error}
            {...field}
            onChange={interceptedOnChange}
            checked={field.value === format(meta.value)}
          />
          {label && <div className="ml-2 leading-tight">{label}</div>}
        </label>
      )}
    </Field>
  )
}

export const ColorRadioButtonFormField = ({
  name,
  label,
  isRequired = false,
  isChanged = false,
  colorClassName = '',
  className = '',
  value,
}) => (
  <Field required={isRequired} type="radio" name={name} value={value}>
    {({ field }) => (
      <label className={classNames('flex flex-col items-center', className)}>
        <div
          className={classNames(
            'h-10 rounded w-full soft-shadow-outline',
            { 'border border-current': isChanged },
            colorClassName,
          )}
        />
        <div className={classNames('text-xs mt-2 px-2 rounded inline-block relative', { 'bg-current': field.checked })}>
          <input
            type="radio"
            required={isRequired}
            {...field}
            className="absolute opacity-0 h-full w-full left-0 right-0"
          />
          <span className={classNames('whitespace-nowrap', { 'text-white': field.checked })}>{label}</span>
        </div>
      </label>
    )}
  </Field>
)

export function RadioListFormField({
  name,
  isRequired = false,
  className = '',
  label = '',
  parse = (v) => v,
  format = (v) => v,
  options,
}) {
  return (
    <div className={className}>
      {label && (
        <FieldLabel name={name} isRequired={isRequired}>
          {label}
        </FieldLabel>
      )}
      <div className="space-y-2 px-1">
        {options.map(({ id, name: label }) => (
          <RadioButtonFormField key={id} value={id} name={name} label={label} isRequired={isRequired} />
        ))}
      </div>
    </div>
  )
}

export const UNIT_ID_KILOGRAM = 'KILOGRAM'
export const UNIT_ID_METRIC_TONNE = 'METRIC_TONNE'

export const UnitSelectFormField = ({
  name,
  unitWeightName,
  className = '',
  label = '',
  possibleUnits = [] as string[],
}) => {
  const { setFieldValue, getFieldProps } = useFormikContext()
  const props = getFieldProps(name)
  const options = useClassifierOptionList('productionUnits')

  const filteredOptions = !possibleUnits?.length
    ? options
    : options.filter((option: any) => {
        return possibleUnits.includes(option.id)
      })
  const singleOption = filteredOptions.length === 1
  const onChange = useCallback(
    (value) => {
      if (value === UNIT_ID_KILOGRAM) {
        setFieldValue(unitWeightName, 1)
      } else if (value === UNIT_ID_METRIC_TONNE) {
        setFieldValue(unitWeightName, 1000)
      }
    },
    [setFieldValue, unitWeightName],
  )

  useEffect(() => {
    if (props.value === '' && singleOption) {
      setFieldValue(name, filteredOptions[0].id)
      onChange(filteredOptions[0].id)
    }
  })

  return (
    <SelectFormField
      label={label}
      value={props.value}
      readOnly={singleOption}
      className={className}
      isRequired
      onValueChange={onChange}
      name={name}
      options={filteredOptions}
    />
  )
}

interface DerivedPercentageFormFieldProps extends Omit<InputFieldProps, 'isChanged type'> {
  name: string
  onValueChange?: (value: string) => void
  fastField?: boolean
  percentageOf: number
}

export const DerivedPercentageFormField = ({
  name,
  percentageOf,
  onValueChange,
  ...props
}: DerivedPercentageFormFieldProps) => {
  const [isValueCleared, setIsValueCleared] = useState(false)

  // keep empty value if user has manually cleared it, instead of immediately replacing it with 0
  const volumeToPercentage = useCallback(
    (value: string) => (isValueCleared ? '' : round((parseFloat(value || '0') / percentageOf) * 100, 2)),
    [percentageOf, isValueCleared],
  )
  const percentageToVolume = useCallback(
    (value: string) => {
      if (value === '') {
        setIsValueCleared(true)
      } else {
        setIsValueCleared(false)
      }
      return (percentageOf * parseFloat(value || '0')) / 100
    },
    [percentageOf, setIsValueCleared],
  )
  const interceptedOnChange = useInterceptedOnChange(name, onValueChange, percentageToVolume)
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <InputField
          {...props}
          {...field}
          name={name}
          type="number"
          value={volumeToPercentage(field.value)}
          id={name}
          isChanged={meta.initialValue !== meta.value}
          hasError={!!meta.error}
          onChange={interceptedOnChange}
        >
          <FormFieldErrorMessage name={name} />
        </InputField>
      )}
    </Field>
  )
}

function useFieldOrFormValue<T>(name: string) {
  const context = useFormikContext()
  return useMemo(() => {
    if (name === '') {
      return [
        (context as FormikContextType<T>).values,
        (context as FormikContextType<T>).setValues,
        (context as FormikContextType<T>).initialValues,
      ] as const
    } else {
      const helpers = context.getFieldHelpers<T>(name)
      return [getIn(context.values, name) as T, helpers.setValue, getIn(context.initialValues, name)] as const
    }
  }, [name, context])
}

function useValidateValueOnRichOptionsChange<T extends RichSelectOptionType>(name: string, options: T[] = []) {
  const [value, setValue] = useFieldOrFormValue<T | null>(name)

  useEffect(() => {
    if (value?.value && !options.find((o) => o.value === value?.value)) {
      setValue(null)
    }
  }, [options, value, setValue])
}

function useValidateValueOnOptionsChange(name: string, options: { id: string }[] = []) {
  const [value, setValue] = useFieldOrFormValue<any>(name)

  useEffect(() => {
    if (value !== '' && !options.find((o) => o.id?.toString() === value?.toString())) {
      setValue('')
    }
  }, [options, value, setValue])
}

function useInterceptedOnChange(
  name: string,
  callback?: (value: any) => void,
  formFieldMapping: (value: any) => any = (value) => value,
) {
  const { setFieldValue } = useFormikContext()
  return useCallback(
    (e) => {
      callback?.(e.target.value)
      return setFieldValue(name, formFieldMapping(e.target.value))
    },
    [setFieldValue, callback, formFieldMapping, name],
  )
}

function useInterceptedOnChangeObject(
  name: string,
  callback?: (value: any) => void,
  formFieldMapping: (value: any) => any = (value) => value,
) {
  const { setValues } = useFormikContext()
  return useCallback(
    (e) => {
      setValues((values) => {
        const mergedValue = Object.assign({}, name ? getIn(values, name) : values, formFieldMapping(e.target.value))
        return name ? setWith(clone(values), name, mergedValue, clone) : mergedValue
      })
      callback?.(e.target.value)
    },
    [setValues, callback, formFieldMapping, name],
  )
}

export function MultiSelectFormFieldWithDescription({
  descriptionName,
  className,
  ...props
}: MultiSelectFormFieldProps & { descriptionName: string }) {
  const { t } = useTranslation()
  return (
    <div className="space-y-2">
      <MultiSelectFormField {...props} className={classNames('min-w-240px', className)} />
      <InputFormField name={descriptionName} placeholder={t('Description')} />
    </div>
  )
}

export function BigLabeledInputFormField({
  label,
  comment = '',
  className,
  ...props
}: InputFormFieldProps & { comment?: string }) {
  return (
    <div className="flex items-center space-x-2">
      <div>
        <h3 className="text-lg">{label}</h3>
        {comment && <h4 className="italic">{comment}</h4>}
      </div>
      <InputFormField {...props} className={classNames('max-w-120px', className)} />
    </div>
  )
}

export function FileInputFormField({ name, children, ...props }) {
  const [, meta, helpers] = useField(name)
  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => helpers.setValue(e.currentTarget.files?.[0]),
    [helpers],
  )

  return (
    <FileInputField
      onChange={handleFileChange}
      isChanged={meta.initialValue !== meta.value}
      hasError={!!meta.error}
      {...props}
    >
      {children}
      <FormFieldErrorMessage name={name} />
    </FileInputField>
  )
}
