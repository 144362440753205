import { debounce } from 'lodash'
import { useCallback, useState } from 'react'

export function useDebounce<T>(initValue: T, timeMs: number = 500): [T, T, (val: T) => void] {
  const [valueDebounced, setValueDebounced] = useState<T>(initValue)
  const [valueLive, setValueLive] = useState<T>(initValue)

  // Ignoring the eslint warning below since we can't make the call to debounce an inline function
  // eslint-disable-next-line
  const setValueDebouncedWithDebounce = useCallback(
    debounce((v) => setValueDebounced(v), timeMs),
    [debounce, setValueDebounced, timeMs],
  )
  const setValue = useCallback(
    (value: T) => {
      setValueLive(value)
      setValueDebouncedWithDebounce(value)
    },
    [setValueLive, setValueDebouncedWithDebounce],
  )
  return [valueDebounced, valueLive, setValue]
}
