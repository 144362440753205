import classNames from 'classnames'
import find from 'lodash/find'
import { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { IoLanguage } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import { RoleId, useClassifiers, useCurrentUser } from 'src/api'
import useAuth from 'src/auth'
import { Button, IconButton, TextAnchorButton, TextButton } from 'src/components/Buttons'
import { Select } from 'src/components/Elements'
import { useLanguageOptions } from 'src/hooks'
import { useClassifiersTranslation } from 'src/hooks/useClassifiersTranslation'
import useActiveRole from 'src/state/role'

import CenterSpinner from './CenterSpinner'
import { SelectField } from './Fields'

export default function Header({ className }) {
  return (
    <div
      className={classNames(
        'w-full border-b border-slate-200 text-sky-900 pr-8 flex flex-row items-center justify-end space-x-5 h-full',
        className,
      )}
    >
      <LanguageSwitcher />
      <UserMenu />
      <HelpPage />
    </div>
  )
}

const HelpPage = () => {
  const { t } = useTranslation()
  return (
    <IconButton
      as="a"
      href="https://reverse-resources.helpscoutdocs.com/"
      icon={IoIosHelpCircleOutline}
      className="w-8 h-8 align-middle"
      title={t('Help')}
      target="_blank"
      rel="noopener noreferrer"
    />
  )
}

const UserMenu = () => {
  const { login, logout, isLoading: isAuthLoading } = useAuth()
  const { data, isLoading } = useCurrentUser()
  const [{ hasAdminRole }] = useActiveRole()
  const { t } = useTranslation()

  return (
    <>
      {isLoading || isAuthLoading ? (
        <CenterSpinner />
      ) : !!data?.user ? (
        <>
          <Trans i18nKey="USER_GREETING" parent="span" className="whitespace-nowrap">
            Hello,{' '}
            <TextAnchorButton as={Link} to="/user" variantColor="gray">
              {{ name: data.user?.fullName ?? t('Unnamed user') }}
            </TextAnchorButton>
            !
          </Trans>
          <div className="flex flex-col">
            <RoleHelperText />
            {(data.user?.isTcAccepted || hasAdminRole) && <RoleChoice roles={data.user.roles} />}
          </div>
          <TextButton onClick={() => logout()}>{t('Log out')}</TextButton>
        </>
      ) : (
        <Button onClick={() => login()}>{t('Log in')}</Button>
      )}
    </>
  )
}

const RoleHelperText = () => {
  const [roleQuery] = useActiveRole()
  const translateActingRole = useClassifiersTranslation('ACTING_ROLE')

  return !(roleQuery.data?.roleId === RoleId.BrandManager || roleQuery.data?.roleId === RoleId.ProjectManager) ? (
    <Trans i18nKey="USER_ACTING_ROLE" parent="div" className="text-xs flex">
      You are acting as:{' '}
      <span className="font-bold ml-1">
        {{ role: roleQuery.data?.roleId ? translateActingRole(roleQuery.data.roleId) : '' }}
      </span>
      <div className="ml-1">(to switch, use the dropdown below)</div>
    </Trans>
  ) : null
}

const ROLE_ORDER = ['ADMIN', 'BRAND_MANAGER', 'ORGANIZATION_MANAGER', 'INVENTORY_MANAGER', 'PROJECT_MANAGER']
const ORGANIZATION_ROLES = ['ORGANIZATION_MANAGER', 'INVENTORY_MANAGER']

const RoleChoice = ({ roles }) => {
  const [{ data: role, isLoading: isRoleLoading }, changeId] = useActiveRole()
  const { data: classifiers, isLoading } = useClassifiers()

  const getRoleName = useCallback((id) => find(classifiers?.roles, { id })?.name || id, [classifiers?.roles])

  const allRoles = useMemo(() => {
    if (role && !roles?.find(({ id }) => id === role.id)) {
      return [...roles, role]
    }
    return roles
  }, [roles, role])

  const options = useMemo(
    () =>
      allRoles
        ?.filter(function isNotAchived(r) {
          // workaround because archiving a facility does not delete associated roles
          if (r.organizations?.length) {
            return r.organizations.every((o) => !o.deletedAt)
          } else if (r.facilities?.length) {
            return r.facilities.every((f) => !f.deletedAt)
          } else if (r.brands?.length) {
            return r.brands.every((f) => !f.deletedAt)
          } else {
            return true
          }
        })
        .sort(function compareRoles(a, b) {
          if (ORGANIZATION_ROLES.includes(a.roleId) && ORGANIZATION_ROLES.includes(b.roleId)) {
            // compare only when both belong to the same group and their organizations are different
            // this separates these roles by organization first and therefore keeps inventory managers
            // and organization managers together by organization
            const organizationNameOrder = compareStrings(getRoleOrganizationName(a), getRoleOrganizationName(b))
            if (organizationNameOrder !== 0) {
              return organizationNameOrder
            }
            // compare by id for similar names because we do not prevent same name organizations for now
            const organizationOrder = compareNumbers(getRoleOrganizationId(a), getRoleOrganizationId(b))
            if (organizationOrder !== 0) {
              return organizationOrder
            }
            // if they were the same (or both nonexistent) we proceed to comparing role ids, moving
            // organization's inventory managers under the organization's manager role
          }
          // compare by role id and resources (if any exist).
          // first non 0 will be returned.
          return (
            compareRoleIdOrder(a, b) ||
            compareResourceNames(a.facilities, b.facilities) ||
            compareResourceNames(a.brands, b.brands) ||
            compareResourceNames(a.projects, b.projects) ||
            0
          )
        })
        .map(function getRoleFullName(r) {
          let name = getRoleName(r.roleId)
          if (r.organizations?.length) {
            name = r.organizations[0].name
          } else if (r.facilities?.length) {
            name = r.facilities[0].organization?.name + '/' + r.facilities[0].name
          } else if (r.brands?.length) {
            name = r.brands[0].name
          } else if (r.projects?.length) {
            name = r.projects[0].name
          }
          return { id: r.id, name }
        }),

    [allRoles, getRoleName],
  )

  return isLoading || isRoleLoading ? (
    <CenterSpinner />
  ) : (
    <Select value={role?.id} onChange={(e) => changeId(e.target.value)} options={options} />
  )
}

function getRoleOrganizationId(role) {
  return role?.facilities?.[0]?.organization?.id || role?.organizations?.[0]?.id
}

function getRoleOrganizationName(role) {
  return role?.facilities?.[0]?.organization?.name || role?.organizations?.[0]?.name
}

function compareRoleIdOrder(a, b) {
  return ROLE_ORDER.findIndex((r) => r === a.roleId) - ROLE_ORDER.findIndex((r) => r === b.roleId)
}

function compareResourceNames(a, b) {
  return compareStrings(a?.[0]?.name, b?.[0]?.name)
}

/**
 * Compares two numbers with special handling for cases when either one or
 * both are not numbers (or are missing).
 * If only one is a number, considers it larger.
 * If neither are numbers, considers them equal.
 */
function compareNumbers(a, b) {
  if (isNaN(a) && isNaN(b)) {
    return 0
  } else if (isNaN(a)) {
    return 1
  } else if (isNaN(b)) {
    return -1
  } else {
    return b - a
  }
}

function compareStrings(a, b) {
  return (a ?? '').localeCompare(b ?? '')
}

function LanguageSwitcher() {
  const [language, setLanguage, availableLanguages] = useLanguageOptions()
  const { t } = useTranslation()
  return (
    <label className="flex items-center space-x-2">
      <IoLanguage className="w-5 h-5" />
      <SelectField
        placeholder={t('Language')}
        isRequired
        value={language}
        options={availableLanguages}
        onChange={setLanguage}
        className="text-sm max-w-100px"
      />
    </label>
  )
}
